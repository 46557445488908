a.badge:hover {
  color: $white;
}

/* task-status-styles.css */

/* Overdue status */
.overdue {
  font-weight: bold;
  color: #ffffff;
  background-color:#fc3142;
  border-radius: 8px;
  padding: 4px 8px;
  height: auto;
  width: 110px;
  text-align:center;
}

.pending {
  font-weight: bold;
  color: #012f7c;
  background-color: #ffffff;
  border: 2px solid #012f7c;
  border-radius: 8px;
  padding: 4px 8px;
  height: auto;
  width: 110px;
  text-align:center;

}
/* In progress status */
.inProgress {
  font-weight: bold;
  color:#ffffff;
  background-color: #012f7c;
  border-radius: 8px;
  padding: 4px 8px;
  height: auto;
  width: 110px;
  text-align:center;
}

/* Completed status */
.completed {
  font-weight: bold;
  color: #ffffff;
  background-color:#53d768;
  border-radius: 8px;
  padding: 4px 8px;
  height: auto;
  width: 110px;
  text-align:center;
}

/* Completed overdue status */
.completedOverdue {
  font-weight: bold;
  color:#ff6900;
  background-color:#53d768;
  border-radius: 8px;
  padding: 4px 8px;
  height: auto;
  width: 110px;
  text-align:center;
}
